import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  ...theme.fontStyles,

  adCard: {
    height: 260,
    maxWidth: 604,
    minWidth: 440,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 6,
    borderWidth: 2,
    borderColor: theme.palette.bg.ghost,
    backgroundClip: 'padding-box',
  },

  adCardContent: {
    ...theme.layouts.flexJCSpaceBetween,
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    height: '100%',
    width: '100%',
    padding: '2.5rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },

  adCardButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
