import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  modal: {
    color: theme.palette.typo.primary,
    padding: '1rem 1.5rem',
  },
  modalTitle: {
    display: 'inline-block!important',
    width: '100%',
  },
  modalContainer: {
    marginTop: '2.5rem',
    display: 'inline-block',
  },
  modalContent: {
    position: 'relative',
  },
  modalInput: {
    marginTop: '1.25rem',
  },
  error: {
    ...theme.fontStyles.text14,
    position: 'absolute',
    left: '0',
    top: '100%',
    color: theme.palette.typo.alert,
    fontWeight: '400',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '2.5rem',
  },
  btn: {
    backgroundColor: theme.palette.typo.link,
    marginLeft: '1rem',
  },
}));
