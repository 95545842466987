import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  durationContainer: {
    ...theme.layouts.flexJCSpaceBetween,
    marginBottom: 19,
    marginTop: 7,
    position: 'relative',
  },
  input: {
    marginBottom: 24,
    position: 'relative',
  },
  disabled: {
    opacity: 0.4,
  },
  description: {
    maxWidth: 600,
    marginBottom: 40,
    display: 'inline-block',
  },
  title: {
    marginBottom: '1.75rem',
    display: 'inline-block!important',
    width: '100%',
  },
  settingsContainer: {
    ...theme.layouts.flexJCSpaceBetween,
    marginBottom: '2rem',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 480,
  },
  inputTitle: {
    marginBottom: '1.5rem',
    position: 'relative',
  },
  checkBox: {
    marginTop: '1rem',
    marginBottom: '0.5rem',
  },
  durationCheckbox: {
    marginTop: '2.5rem',
    marginBottom: '0.5rem',
  },
  counter: {
    width: 170,
    marginTop: 5,
    marginBottom: 24,
  },
  imageContainer: {
    width: 370,
    marginRight: 120,
    position: 'relative',
  },
  hiddenInput: {
    width: 0,
    height: 0,
  },
  image: {
    width: 370,
    height: 184,
    objectFit: 'cover',
    marginBottom: 30,
  },
  imagePlaceholder: {
    width: 370,
    height: 184,
    ...theme.layouts.flexCenter,
    borderRadius: '0.375rem',
    backgroundColor: theme.palette.control.default.bg,
    marginBottom: 30,
    position: 'relative',
  },
  imgButton: {
    width: '100%',
  },
  imgError: { position: 'absolute', bottom: -20, left: 0 },
}));
